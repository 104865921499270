import * as React from "react";
import { Body } from "../components/Body";
import EquipmentRental from "../assets/icons/Equipment-Rental.jpg";
import NewEquipment from "../assets/icons/new-equipment.jpg";
import Exchange from "../assets/icons/exchange.jpg";
import SaleRent from "../assets/icons/sale-rent-2.jpg";
import TieUps from "../assets/icons/tieups.png";

export default function Solutions() {
  return (
    <Body className="page-template page-template-template-parts page-template-solutions page-template-template-partssolutions-php page page-id-19 has-header-image page-two-column title-tagline-hidden colors-light">
      <div className="innerbanner">
        <h1>Solutions </h1>
      </div>
      <section className="solutionsection">
        <div className="container">
          <div className="row">
            <div className="scrolll">
              <ul className="nav nav-tabs">
                <li className="listitem active">
                  <a data-toggle="tab" href="#service1">
                    Rental Services
                  </a>
                </li>
                <li className="listitem ">
                  <a data-toggle="tab" href="#service2" id="valueadd">
                    Value Added Services
                  </a>
                </li>
                <li className="listitem ">
                  <a data-toggle="tab" href="#service3" id="assestsdis">
                    Asset Classes we rent
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div id="service1" className="tab-pane fade in active">
                <div className="servicebox">
                  <div className="serviceblock wow fadeInUp">
                    <img
                      src={EquipmentRental}
                      className="serviceimg"
                      alt="EquipmentRental"
                    />
                    <h2>New Equipment Rental</h2>

                    <p>
                      Connect alleviates client&apos;s investment risk by
                      offering customized technology refresh options to help
                      ensure that they have ready access to the new equipment
                      they need even during their initial rental term. New
                      Equipment rental can free up your business &apos; capital,
                      release your business from the costs associated with
                      equipment ownership. It&apos;s an ideal way to use
                      equipment while you conserve your cash.
                      <br />
                      We also offer programs that enable clients to trade-in or
                      return existing equipment, and possibly get cash or credit
                      towards a new rental program.
                      <br />
                      With flexible options of month-to-month or rent-to-own
                      programs, Connect ensures you get the most out of
                      equipment. You only pay for the term you use. Renting with
                      Connect is on a consultative approach with transparency.
                      Our expert sales engineers will find the best solution for
                      your need.
                    </p>
                  </div>

                  <div className="serviceblock wow fadeInUp">
                    <img
                      src={NewEquipment}
                      className="serviceimg"
                      alt="NewEquipment"
                    />
                    <h2>Sell and Rent Back</h2>

                    <p>
                      If client owns equipment, but wants the flexibility and
                      disposition benefits of renting, they can sell owned
                      assets at WDV and rent them back for a mutually agreed
                      term. As a result, client enjoys benefits like an
                      immediate cash infusion from the sale, expedited migration
                      plan to a new solution, safeguard the availability of
                      capital stock, and reduced risk of obsolescence. With this
                      service, client can convert its capital assets to liquid
                      assets.
                    </p>
                  </div>

                  <div className="serviceblock wow fadeInUp">
                    <img src={Exchange} className="serviceimg" alt="Exchange" />
                    <h2>Refresh Plan</h2>

                    <p>
                      A plan which gives our clients the flexibility to return /
                      upgrade their existing portfolio and refresh their
                      equipment prior to the expiry of the term. This helps our
                      clients to develop a planned procurement strategy with the
                      certainty of operating cash flows, and gives the
                      flexibility to the client to upgrade what they need to and
                      when they need to.
                    </p>
                  </div>

                  <div className="serviceblock wow fadeInUp">
                    <img src={SaleRent} className="serviceimg" alt="SaleRent" />
                    <h2>Short Term Rentals</h2>

                    <p>
                      When you have a requirement for something very specific,
                      short term renting is the ideal solution. Short term
                      renting can be very beneficial when the requirement is as
                      short as 1 month to 1 year. Short Term Renting can be used
                      for certain projects which require specific hardware,
                      testing of newly developed software products on different
                      hardware and configurations, as standby equipment until
                      the ordered equipment actually arrives from suppliers,
                      training and conferences, growth in your business or for
                      temporary staff.
                    </p>
                  </div>

                  <div className="serviceblock wow fadeInUp">
                    <img src={TieUps} className="serviceimg" alt="TieUps" />
                    <h2>Strategic Tie ups</h2>

                    <p>
                      With the growing acceptance for usage of assets on rent,
                      any business entity would require their supplier to
                      provide tailored and competitive rental structure with
                      asset management packages , by reducing the usage of their
                      credit lines and more productive usage of working capital
                      . This can be conveniently accomplished with strategic tie
                      ups with us and utilise our residual management
                      capabilities and systems.
                    </p>
                    <p>
                      Connect has been a successful strategic tie up partners
                      for large OEM ‘s/ Manufacturers to provide customised
                      rental plans and asset management services on their
                      products vide opportunity-based alliances/ being one of
                      your strategic Partner/ dedicated rental partners.
                    </p>
                    <p>
                      What Connect Offers :<br />
                      Enable you to sell your products under customized rental
                      plans
                      <br />
                      Enable your customer to plan his asset management in a
                      professional manner with options to extend /return/refresh
                    </p>
                    <p>
                      Our rental solutions allow you to offer a competitive,
                      market-leading solution to your clients, without having to
                      invest in costly additional expertise or processes.
                    </p>
                  </div>
                </div>
              </div>
              <div id="service2" className="tab-pane fade">
                <div className="servicebox">
                  <div className="serviceblock wow fadeInUp">
                    <img src="" className="serviceimg" />
                    <h2>Asset Disposable Services</h2>

                    <p>
                      A service designed to help clients uncover the value of
                      unwanted equipment. With our Asset disposable Services, we
                      manage the retirement of client’s equipment, we do it in a
                      way that can help the client to safeguard their
                      proprietary information, complies with applicable
                      environmental laws and regulations and may even provide
                      you with a financial return on remarketing able equipment.
                    </p>
                  </div>

                  <div className="serviceblock wow fadeInUp">
                    <img src="" className="serviceimg" />
                    <h2>Strategic & Advisory Services</h2>

                    <p>
                      This includes consultancy services on Asset Planning,
                      Asset Tracking, Physical Inventory & Asset Audit, Guidance
                      on useful life of assets and Cost Benefit analysis on Rent
                      v/s Buy.
                    </p>
                  </div>
                </div>
              </div>

              <div id="service3" className="tab-pane fade">
                <div className="servixeflexrow">
                  <div className="servixeflexcolumn wow fadeInUp">
                    <div className="serviceboxul dotslist">
                      <h1>RETAIL/OFFICE ASSETS </h1>
                      <ul>
                        <li>Furniture </li>
                        <li>Fixtures </li>
                        <li>Air conditioners </li>
                        <li>HVAC Equipment </li>
                        <li>Bar Code Reader </li>
                        <li>Bar Code Equipments </li>
                        <li>Bill Counters </li>
                        <li>Cash Registers </li>
                        <li>Coin Counting Machines </li>
                        <li>Currency Counters </li>
                        <li>Currency Counting Machines </li>
                        <li>Digital Signage </li>
                        <li>Handheld Laser Scanners </li>
                        <li>Kiosks </li>
                        <li>Money Counter Machines </li>
                        <li>Portable Data Terminals </li>
                        <li>POS Display and Peripherals </li>
                        <li>Receipt Printer </li>
                        <li>Tag Guns </li>
                        <li>Vending Machine </li>
                      </ul>
                    </div>
                  </div>

                  <div className="servixeflexcolumn wow fadeInUp">
                    <div className="serviceboxul dotslist">
                      <h1>ATM and Related Assets </h1>
                      <ul>
                        <li>ATM and Related Assets </li>
                        <li>Currency counting & sorting machines </li>
                        <li>Counterfeit note detectors </li>
                        <li>Statement printers </li>
                        <li>Cash dispensers </li>
                        <li>
                          Office automation products including document
                          management systems{" "}
                        </li>
                        <li>Archive systems </li>
                        <li>Packing systems for coins and bank notes, </li>
                        <li>
                          Communication systems - Tele-working solutions,{" "}
                        </li>
                        <li>V-SATs </li>
                        <li>Data networks, </li>
                      </ul>
                    </div>
                  </div>

                  <div className="servixeflexcolumn wow fadeInUp">
                    <div className="serviceboxul dotslist">
                      <h1>Information Technology Assets </h1>
                      <ul>
                        <li>Computers /Laptops /Desktops </li>
                        <li>Server hardware and software </li>
                        <li>Telecommunications equipment </li>
                        <li>POS equipment </li>
                        <li>Printers and Paper Shredders </li>
                      </ul>
                    </div>
                  </div>

                  <div className="servixeflexcolumn wow fadeInUp">
                    <div className="serviceboxul dotslist">
                      <h1>Other Assets </h1>
                      <ul>
                        <li>Plant and Machinery </li>
                        <li>Display units </li>
                        <li>Construction Equipments </li>
                        <li>Healthcare/Medical Equipments </li>
                        <li>Transportation Equipments </li>
                        <li>Warehousing Equipments </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row erdata wow fadeInUp">
                    <h2> Can&apos;t see the asset that you want on Rent? </h2>
                    <p>
                      As the lists of equipments that we rent is very large, it
                      is impractical to list every item here. Rest assured we
                      rent almost all assets classes. Please get in touch with
                      us for resolution of your query.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
}
