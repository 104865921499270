import * as React from "react";
import { Body } from "../components/Body";

export default function IndustriesWeCaterToScreen() {
  return (
    <Body className="page-template page-template-template-parts page-template-industries-we-cater-to page-template-template-partsindustries-we-cater-to-php page page-id-25 has-header-image page-two-column title-tagline-hidden colors-light">
      <section className="client">
        <div className="innerbanner">
          <h1> Industries we cater to </h1>
        </div>

        <div className="container">
          <div className="row">
            <div className="clientnamebox">
              <div className="row">
                <div className="col-md-12">
                  <ul>
                    <li className="fadeIn wow"> E Payment & ATM Management </li>
                    <li className="fadeIn wow"> Information Technology </li>
                    <li className="fadeIn wow"> BPO & Managed services </li>
                    <li className="fadeIn wow"> Manufacturing </li>
                    <li className="fadeIn wow"> Infrastructure </li>
                    <li className="fadeIn wow"> Retail </li>
                    <li className="fadeIn wow"> Insurance </li>
                    <li className="fadeIn wow"> Medical & Pharmaceutical </li>
                    <li className="fadeIn wow"> Media & Animation </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
}
