import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "./Home";
import { AboutUs } from "./AboutUs";
import WhyRent from "./WhyRent";
import Solutions from "./Solutions";
import CSRScreen from "./CSR";
import IndustriesWeCaterToScreen from "./IndustriesWeCaterToScreen";
import CareersScreen from "./Careers";
import ReachUsScreen from "./ReachUs";

export default function Screens() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" Component={HomeScreen} />
        <Route path="/about-us" Component={AboutUs} />
        <Route path="/why-rent" Component={WhyRent} />
        <Route path="/solutions" Component={Solutions} />
        <Route path="/csr" Component={CSRScreen} />
        <Route
          path="/industries-we-cater-to"
          Component={IndustriesWeCaterToScreen}
        />
        <Route path="/career" Component={CareersScreen} />
        <Route path="/contact" Component={ReachUsScreen} />
      </Routes>
    </BrowserRouter>
  );
}
