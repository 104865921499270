import * as React from "react";

interface Props {
  size: number;
  horizontal?: boolean;
}

export const Spacer = ({ size, horizontal = false }: Props) => {
  const defaultValue = "auto";
  return (
    <div
      style={{
        width: horizontal ? size : defaultValue,
        height: !horizontal ? size : defaultValue,
      }}
    />
  );
};
