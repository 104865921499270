import * as React from "react";
import { Wrapper } from "./Wrapper";
import { Footer } from "./Footer";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Body = ({ children, className }: Props) => {
  return (
    <body className={className}>
      <Wrapper>{children}</Wrapper>
      <Footer />
    </body>
  );
};
