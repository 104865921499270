import * as React from "react";
import { Body } from "../../components/Body";
import { Formik } from "formik";
import { Form } from "./components/Form";
import * as Yup from "yup";

const intial_values = {
  name: "",
  mobile: "",
  email: "",
  description: "",
};

export default function ReachUsScreen() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ name, mobile, email, description }: typeof intial_values) => {
      return;
    },
    [],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string().required("Mobile number is required"),
    email: Yup.string()
      .email("must be a valid email")
      .required("Email is required"),
  });

  return (
    <Body className="page-template page-template-template-parts page-template-contact page-template-template-partscontact-php page page-id-30 has-header-image page-two-column title-tagline-hidden colors-light">
      <section className="contact">
        <div className="innerbanner">
          <h1 className="wow fadeIn">Reach Us</h1>
        </div>

        <div className="container">
          <h2>Registered and Corporate Office</h2>
          <div className="contactboxx">
            <div className="row">
              <div className="col-md-4 col-sm-6 wow slideInUp">
                <div className="addbox match">
                  <h3> Mumbai </h3>
                  <p>
                    Registered Address: 103, B Wing, Satellite Gazebo, Andheri
                    Ghatkopar Link Road, Andheri East,
                    <br />
                    Mumbai -400093 <br />
                    Tel:
                    <a href="tel:+91 22 4879 7600">+91 22 4879 7600</a>
                    <br />
                    CIN : U74120MH2011PTC220805
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 wow slideInUp">
                <div className="addbox match">
                  <h3> NCR </h3>
                  <p>
                    225, 1 st Floor DLF South Court, D4 Commercial Complex,
                    Saket,
                    <br />
                    New Delhi – 110017 <br />
                    Tel:
                    <a href="tel:+91 11 41802518">+91 11 41802518</a>
                    <br />
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 wow slideInUp">
                <div className="addbox match">
                  <h3> Bangalore </h3>
                  <p>
                    Suite No. 640, Level 6, North Tower, ITC Green Centre,
                    Marutiseva Nagar, Banaswadi Main Road,
                    <br />
                    Bangalore - 560 005 <br />
                    Tel:
                    <a href="tel:+91 9916611519">+91 9916611519</a>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row contactform">
            <div className="col-md-6">
              <div className="enquiry">
                <h2 className="wow zoomIn">GET IN TOUCH</h2>
                <p>
                  Please tell me what you’re looking for, and We’ll be in touch
                  soon…
                </p>
                <div className="iconbox">
                  <h3>For Sales Enquiry -</h3>
                  <ul>
                    <li>
                      {/* <h4>Sudhanshu Sharma</h4> */}
                      <a href="tel:+91 22 4879 7600">+91 22 4879 7600</a>
                      <br />
                      <a href="mailto:connect@connectrpl.com">
                        connect@connectrpl.com
                      </a>
                    </li>

                    {/* <li>
                      <h4>Rohit Kakkar</h4>
                      <a href="tel:9811295108">+91 98112 95108</a> <br />
                      <a href="mailto:rohit@connectrpl.com">
                        rohit@connectrpl.com
                      </a>
                    </li> */}
                  </ul>
                  <h3>For Career Opportunities and Other Enquiries -</h3>
                  <a href="mailto:connect@connectrpl.com">
                    connect@connectrpl.com
                  </a>
                </div>

                <ul className="social">
                  <li className="wow fadeIn">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://in.linkedin.com/company/connectrpl"
                    >
                      <i className="fa fa-linkedin icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className="contact-us wow fadeIn">
                <Formik
                  initialValues={intial_values}
                  onSubmit={handleSubmit}
                  validateOnChange
                  validateOnMount
                  validationSchema={validationSchema}
                >
                  <Form />
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
}
