import * as React from "react";
import { Body } from "../components/Body";
import Banner from "../assets/icons/banner1.png";
import Welcome from "../assets/icons/welcomeimg.png";
import Setting from "../assets/icons/settingicon.png";

const heading1 = {
  maxWidth: "450px",
};

export default function HomeScreen() {
  return (
    <Body className="home page-template page-template-template-parts page-template-home-template page-template-template-partshome-template-php page page-id-7 twentyseventeen-front-page has-header-image page-two-column title-tagline-hidden colors-light">
      <div className="home-page">
        <section className="banner">
          <div className="banner-slider">
            <div className="slide">
              <img src={Banner} alt="banner" />
              <div className="banneruptext">
                <h1 className="wow fadeInLeft">
                  Extending Smart & <br />
                  <span>Innovative Rental Solution</span>
                </h1>
                <p>
                  Connect follows a consultative approach for tailoring
                  equipment renting solutions for its clients by bringing in
                  their residual management capabilities along with the
                  team&apos;s widespread industry experience.
                </p>

                <a
                  className="readmorebtn wow fadeInLeft"
                  data-wow-delay=".5s"
                  href="/about-us"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="welcome">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="welcometext">
                  <h3 className="wow fadeInLeft">WELCOME TO</h3>
                  <h1 className="wow fadeInLeft" style={heading1}>
                    Connect Residuary Private Limited{" "}
                  </h1>
                  <p className="wow fadeIn">
                    A company formed by a group of professionals who have been
                    connected with the equipment renting industry for over 36
                    years in India and Overseas. The prime business of the
                    company is equipment renting based on Residual management
                    capability.{" "}
                  </p>
                  <a
                    className="readmorebtn wow fadeInLeft"
                    data-wow-delay=".5s"
                    href=""
                  >
                    Read More
                  </a>
                </div>
              </div>
              <div className="col-md-4 wow fadeInRight">
                <div className="imgbox">
                  <img src={Welcome} alt="welcome" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="services">
          <div className="container">
            <div className="setting-text wow fadeIn">
              <div className="settingbox">
                <img src={Setting} alt="setting" />
                <h2>
                  Our <br /> <span>Solutions</span>
                </h2>
              </div>
            </div>
            <div className="rentbox wow fadeIn">
              <div className="row">
                <div className="col-md-6 rightborder">
                  <a href="/solutions/#service1">
                    <h2>RENT/ RENTING SERVICESS</h2>
                  </a>

                  <ul>
                    <li>
                      <a href="/solutions">New Equipment Rental</a>
                    </li>
                    <li>
                      <a href="/solutions">Sell and Rent Back</a>
                    </li>
                    <li>
                      <a href="/solutions">Refresh Plan</a>
                    </li>
                    <li>
                      <a href="/solutions">Short Term Rentals</a>
                    </li>
                    <li>
                      <a href="/solutions">Strategic Tie ups</a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-6">
                  <a href="/solutions/#service2">
                    <h2>Value Added Services </h2>
                  </a>
                  <ul>
                    <li>
                      <a href="/solutions">Asset Disposable Services</a>
                    </li>
                    <li>
                      <a href="/solutions">Strategic & Advisory Services</a>
                    </li>
                    <li>
                      <a href="/solutions">Asset Tagging</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <a href="/solutions/#service3">
                    <h2 className="head2">Asset Classes we rent </h2>
                  </a>
                  <ul className="countul">
                    <li>
                      <a href="/solutions">Plant and Machinery</a>
                    </li>
                    <li>
                      <a href="/solutions">Furniture and Fixtures</a>
                    </li>
                    <li>
                      <a href="/solutions">Retail/ Office Assets</a>
                    </li>
                    <li>
                      <a href="/solutions">Information Technology Assets</a>
                    </li>
                    <li>
                      <a href="/solutions">ATM and Related Assets</a>
                    </li>
                    <li>
                      <a href="/solutions">Medical and Pharmaceuticals</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="blog">
          <div className="container">
            <h1>Blog</h1>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-4 wow fadeInUp">
                  <div className="blogbox">
                    <img
                      src="/images/blogimg1.png"
                      alt=""
                      className="img-responsive"
                    />
                    <div className="blogtext">
                      <h2>
                        Are you looking for hedging technology obsolescence?
                      </h2>
                      <div className="blogicon">
                        <div className="icon1">
                          <i className="fa fa-user-o"></i>
                          <span>Connectrpl</span>
                        </div>
                        <div className="icon2">
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                          <span>09, Aug, 2019.</span>
                        </div>
                      </div>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum{" "}
                      </p>

                      <div className="readmsg">
                        <a href="#">Read More</a>
                        <div className="msg">
                          {" "}
                          <i
                            className="fa fa-comments-o"
                            aria-hidden="true"
                          ></i>
                          <span>1</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 wow fadeInUp">
                  <div className="blogbox">
                    <img
                      src="/images/blogimg2.png"
                      alt=""
                      className="img-responsive"
                    />
                    <div className="blogtext">
                      <h2>Are you looking at an Asset Lite Balance sheet?</h2>
                      <div className="blogicon">
                        <div className="icon1">
                          <i className="fa fa-user-o"></i>
                          <span>Connectrpl</span>
                        </div>
                        <div className="icon2">
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                          <span>09, Aug, 2019.</span>
                        </div>
                      </div>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum{" "}
                      </p>

                      <div className="readmsg">
                        <a href="#">Read More</a>
                        <div className="msg">
                          {" "}
                          <i
                            className="fa fa-comments-o"
                            aria-hidden="true"
                          ></i>
                          <span>1</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 wow fadeInUp">
                  <div className="blogbox">
                    <img
                      src="/images/blogimg3.png"
                      alt=""
                      className="img-responsive"
                    />
                    <div className="blogtext">
                      <h2>Are you planning to purchase new equipment?</h2>
                      <div className="blogicon">
                        <div className="icon1">
                          <i className="fa fa-user-o"></i>
                          <span>Connectrpl</span>
                        </div>
                        <div className="icon2">
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                          <span>09, Aug, 2019.</span>
                        </div>
                      </div>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum{" "}
                      </p>

                      <div className="readmsg">
                        <a href="#">Read More</a>
                        <div className="msg">
                          {" "}
                          <i
                            className="fa fa-comments-o"
                            aria-hidden="true"
                          ></i>
                          <span>1</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </Body>
  );
}
