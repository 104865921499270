import * as React from "react";

export const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer">
        <div className="container">
          <div className="footermenu">
            <div className="menu-footer-menu-container">
              <ul id="menu-footer-menu" className="menu">
                <li
                  id="menu-item-45"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-7 current_page_item menu-item-45"
                >
                  <a href="/" aria-current="page">
                    Home
                  </a>
                </li>
                <li
                  id="menu-item-124"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-124"
                >
                  <a href="/about-us">About Us</a>
                </li>
                <li
                  id="menu-item-43"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-43"
                >
                  <a href="/why-rent">Why Rent</a>
                </li>
                <li
                  id="menu-item-42"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-42"
                >
                  <a href="/solutions">Solutions</a>
                </li>
                <li
                  id="menu-item-41"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-41"
                >
                  <a href="/csr">CSR</a>
                </li>
                <li
                  id="menu-item-40"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-40"
                >
                  <a href="/industries-we-cater-to">Industries we cater to</a>
                </li>
                <li
                  id="menu-item-39"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-39"
                >
                  <a href="/career">Career</a>
                </li>
                <li
                  id="menu-item-55"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-55"
                >
                  <a href="/contact">Reach Us</a>
                </li>
              </ul>
            </div>
          </div>

          <p>
            Copyright © 2023 Connect Residuary Private Limited. All rights
            Reserved | Design & Developed by -{" "}
            <a href="http://www.innovins.com" target="_blank" rel="noreferrer">
              Innovins
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};
