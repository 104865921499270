import * as React from "react";
import { Body } from "../components/Body";

export default function WhyRent() {
  return (
    <Body className="page-template page-template-template-parts page-template-whyrent page-template-template-partswhyrent-php page page-id-17 has-header-image page-two-column title-tagline-hidden colors-light">
      <div className="innerbanner">
        <h1 className="wow fadeIn">Why Rent</h1>
      </div>

      <section className="whyrentsection">
        <div className="container">
          <div className="row">
            <p className="growtext">Use our money to grow... </p>
            <div className="qustnpara"> RENT or BUY ? </div>
            <div className="objectivebox">
              <h2 className="wow fadeIn">What are your objectives?</h2>
              <ul>
                <li className="wow fadeIn">
                  {" "}
                  Are you planning to purchase new equipment?
                </li>
                <li className="wow fadeIn">
                  {" "}
                  Are you looking to conserve capital?
                </li>
                <li className="wow fadeIn">
                  {" "}
                  Are you looking at an Asset Lite Balance sheet?
                </li>
                <li className="wow fadeIn">
                  {" "}
                  Are you looking for hedging technology obsolescence?
                </li>
                <li className="wow fadeIn">
                  {" "}
                  Has disposing off used equipment been a challenge?
                </li>
              </ul>

              <p className="out wow fadeIn" data-wow-delay="1s">
                {" "}
                If you have answered YES to any of these questions then, renting
                should be an option to consider.{" "}
              </p>
            </div>
          </div>
          <div className="benefits dotslist">
            <h3> Benefits</h3>

            <ul>
              <ul>
                <li className="wow fadeInUp">
                  Economical and more efficient way of paying for equipment that
                  depreciates in value and blocks capital
                </li>
              </ul>
              <ul>
                <li className="wow fadeInUp">
                  Single window solution for multiple equipment className such
                  as IT, Furniture, Fit Outs, Office equipment etc.
                </li>
              </ul>
              <ul>
                <li className="wow fadeInUp">
                  Flexible End of term Options – return, re-rent, upgrade some
                  or all Equipment
                </li>
              </ul>
              <ul>
                <li className="wow fadeInUp">
                  Flexibility to choose the make, configuration and purchase
                  price.
                </li>
              </ul>
              <ul>
                <li className="wow fadeInUp">
                  Enable Technology refresh through pre-defined end of life
                  strategy{" "}
                  <ul>
                    <li className="wow fadeInUp">
                      Hedge against technology obsolescence
                    </li>
                  </ul>
                  <ul>
                    <li className="wow fadeInUp">
                      Implement a refresh methodology that becomes part of the
                      overall business strategy
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li className="wow fadeInUp">
                  Eliminate asset disposition concerns and cost{" "}
                  <ul>
                    <li className="wow fadeInUp">
                      Implement a secure and environmentally friendly disposal
                      without the hassles
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li className="wow fadeInUp">Ensure regulatory compliance</li>
              </ul>
              <ul>
                <li className="wow fadeInUp">
                  Enable better planning{" "}
                  <ul>
                    <li className="wow fadeInUp">
                      Make predictable payments instead of a large upfront cash
                      purchase and have better financial planning.
                    </li>
                  </ul>
                  <ul>
                    <li className="wow fadeInUp">
                      Transition from existing equipment, acquire new
                      technology, manage that technology then retire it cost
                      effectively
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li className="wow fadeInUp">
                  Spread costs over the rent term with better ROI{" "}
                  <ul>
                    <li className="wow fadeInUp">
                      Lower Total Cost of Ownership over the useful life of the
                      solution
                    </li>
                  </ul>
                  <ul>
                    <li className="wow fadeInUp">Improves efficiency ratios</li>
                  </ul>
                </li>
              </ul>
            </ul>

            {/* <ul>
                <li className="wow fadeInUp">
                  Economical and more efficient way of paying for equipment that
                  depreciates in value and blocks capital
                </li>
                <li className="wow fadeInUp">
                  Single window solution for multiple equipment className such
                  as IT, Furniture, Fit Outs, Office equipment etc.
                </li>
                <li className="wow fadeInUp">
                  Flexible End of term Options – return, re-rent, upgrade some
                  or all Equipment
                </li>
                <li className="wow fadeInUp">
                  Flexibility to choose the make, configuration and purchase
                  price.
                </li>
                <li className="wow fadeInUp">
                  Enable Technology refresh through pre-defined end of life
                  strategy
                  <ul>
                    <li className="wow fadeInUp">
                      Hedge against technology obsolescence
                    </li>
                    <li className="wow fadeInUp">
                      Implement a refresh methodology that becomes part of the
                      overall business strategy
                    </li>
                  </ul>
                </li>
                <li className="wow fadeInUp">
                  Eliminate asset disposition concerns and cost
                  <ul>
                    <li className="wow fadeInUp">
                      Implement a secure and environmentally friendly disposal
                      without the hassles
                    </li>
                  </ul>
                </li>

                <li className="wow fadeInUp">Ensure regulatory compliance</li>

                <li className="wow fadeInUp">
                  Enable better planning
                  <ul>
                    <li className="wow fadeInUp">
                      Make predictable payments instead of a large upfront cash
                      purchase and have better financial planning.
                    </li>
                    <li className="wow fadeInUp">
                      Transition from existing equipment, acquire new
                      technology, manage that technology then retire it cost
                      effectively
                    </li>
                  </ul>
                </li>
                <li className="wow fadeInUp">
                  Spread costs over the rent term with better ROI
                  <ul>
                    <li className="wow fadeInUp">
                      Lower Total Cost of Ownership over the useful life of the
                      solution
                    </li>
                    <li className="wow fadeInUp">Improves efficiency ratios</li>
                  </ul>
                </li>
              </ul> */}
          </div>
        </div>
      </section>
    </Body>
  );
}
