import * as React from "react";
import { Body } from "../components/Body";
import Ankush from "../assets/icons/ankush.png";
import Rohit from "../assets/icons/rohit.png";
import Passion from "../assets/icons/passion.png";
import Intergrity from "../assets/icons/integrity.png";
import Driven from "../assets/icons/driven.png";
import Community from "../assets/icons/community.png";
import AboutImg from "../assets/icons/aboutimg.png";

const animatedModalStyle = {
  display: "none",
};

const managementeamStyle = {
  display: "flex",
  justifyContent: "center",
};

export function AboutUs() {
  return (
    <Body className="page-template page-template-template-parts page-template-about-us page-template-template-partsabout-us-php page page-id-118 has-header-image page-two-column title-tagline-hidden colors-light">
      <div className="innerbanner">
        <h1 className="wow fadeIn">About Us </h1>
      </div>
      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="abouttext">
                <p>
                  A company formed by a group of professionals who have been
                  connected with the equipment renting industry for over 36
                  years in India and Overseas. The prime business of the company
                  is equipment renting based on Residual management
                  capabilities. As an asset lifecycle management company, our
                  focus is to engage with corporates to cater to their
                  asset-based needs for expansion. Our transparent and
                  consultative approach helps us to deliver renting solutions to
                  meet customer requirements.
                </p>
                <p>
                  We also offer integrated asset tracking solutions for clients
                  to manage the rented assets across the organization. It helps
                  the Client to visualize and analyze the current engagement
                  with Connect.
                </p>
                <p>
                  No matter what asset className you need to procure, we can
                  assist you by customizing our solution to help you optimize
                  your cash flow and/or maximize your purchasing power.
                </p>
                <p>
                  With our head office in Mumbai and aptly supported by sales
                  offices in NCR and Bangalore. We cater to Pan India market
                  through a well-established platform and network.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="imgboxxx wow fadeInUp">
                <img src={AboutImg} alt="aboutimg" className="img-responsive" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="directors">
        <div className="container">
          <h1 className="wow fadeIn"> Board of Directors </h1>
          <div className="dir-slider">
            <div className="slide">
              <div className="dirbox match">
                <div className="dirimgbox">
                  <img src={Ankush} alt="ankush" />
                </div>
                <h2>ANKUSH BHAN</h2>
                <h3>Founder & Director</h3>
                <p>
                  A motorbike enthusiast, who enjoys adventure sport started his
                  career with Public Relations from where he stepped into the
                  Equipment Renting industry in 2002. For over 18 years, he has
                  been servicing this industry both in India and Overseas.
                  Ankush holds a BCom (Hons) degree from Sri Ram College of
                  Commerce –Delhi and a Masters degree in Business Management
                  from Mumbai University.
                </p>
              </div>
            </div>
            <div className="slide">
              <div className="dirbox match">
                <div className="dirimgbox">
                  <img src={Rohit} alt="rohit" />
                </div>
                <h2>Rohit Kakkar</h2>
                <h3>Founder & Director</h3>
                <p>
                  An engineer from NIT (National Institute of Technology-
                  Jamshedpur) and MBA from Symbiosis - Pune started his career
                  with Mahindra Sintered Products as Management Trainee. After
                  completing his MBA, Rohit worked with Wipro Infotech for 4
                  years before moving to equipment renting Industry in 2004.
                  Post 2004, he has successfully handled senior management
                  positions in various asset management / operating lease
                  companies before the conception of Connect.
                </p>
              </div>
            </div>
            <div className="slide">
              <div className="dirbox match">
                <div className="dirimgbox">
                  <img src={Rohit} alt="rohit" />
                </div>
                <h2>Rohit Kakkar</h2>
                <h3>Founder & Director</h3>
                <p>
                  An engineer from NIT (National Institute of Technology-
                  Jamshedpur) and MBA from Symbiosis Institute of telecom
                  Management - Pune started his career with Mahindra Sintered
                  Products. He worked with Wipro Infotech Ltd before stepping
                  into to Equipment Renting Industry in 2004. Rohit is a
                  swimming enthusiast.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="keyteam">
        <div className="container">
          <h1 className="wow fadeIn">Management Team </h1>
          <div className="row" style={managementeamStyle}>
            <div className="col-md-4">
              <div className="dirbox match">
                <div className="dirimgbox">
                  <img src={Ankush} alt="ankush" />
                </div>
                <h2>Ankush Bhan</h2>
                <h3>Founder &Director</h3>
                <p>
                  A motorbike enthusiast, who enjoys adventure sport started his
                  career with Public Relations from where he stepped into the
                  Equipment Renting industry in 2002. For over 18 years, he has
                  been servicing this industry both in India and Overseas.
                  Ankush holds a BCom (Hons) degree from Sri Ram College of
                  Commerce –Delhi and a Masters degree in Business Management
                  from Mumbai University.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dirbox match">
                <div className="dirimgbox">
                  <img src={Rohit} alt="rohit" />
                </div>
                <h2>Rohit Kakkar</h2>
                <h3>Founder &Director</h3>
                <p>
                  An engineer from NIT (National Institute of Technology-
                  Jamshedpur) and MBA from Symbiosis - Pune started his career
                  with Mahindra Sintered Products. He also worked with Wipro
                  Infotech before stepping into the equipment renting Industry
                  in 2004. Rohit is a swimming enthusiast{" "}
                </p>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="dirbox match">
                <div className="dirimgbox">
                  <img src={Manager} alt="sudhanshu" />
                </div>
                <h2>Sudhanshu Sharma</h2>
                <h3>Chief Executive Officer</h3>
                <p>
                  A graduation in Automobile Engineering &MBA in Marketing, he
                  has over 21 years of experience in providing financial
                  solutions for various asset categories. Earlier at IBM, he
                  started his Career as a West Region Seller and grew as Country
                  Executive in a short span of 3 years. He has been awarded for
                  5 consecutive years with Hundred Percent Club award and also
                  was presented with the most prestigious “Best of IBM “award.
                  At HP Financial Services he was managing the Western Region
                  business, along with Channel management for IT financing
                  solutions. Earlier to that, he held Branch Management and Key
                  Account Management positions for the financing units of Tata
                  Motors and ORIX{" "}
                </p>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div className="dirbox match">
                <div className="dirimgbox">
                  <img src={Rohit} alt="rohit" />
                </div>
                <h2>Rohit Kakkar</h2>
                <h3>Founder & Director</h3>
                <p>
                  An engineer from NIT (National Institute of Technology-
                  Jamshedpur) and MBA from Symbiosis - Pune started his career
                  with Mahindra Sintered Products. He also worked with Wipro
                  Infotech before stepping into equipment renting Industry in
                  2004. Rohit is a swimming enthusiast
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dirbox match">
                <div className="dirimgbox">
                  <img src={Manager} alt="Sudhanshu Sharma" />
                </div>
                <h2>Sudhanshu Sharma</h2>
                <h3>Chief Executive Officer</h3>
                <p>
                  A graduation in Automobile Engineering & MBA in Marketing, he
                  has over 21 years of experience in providing financial
                  solutions for various asset categories. Earlier at IBM, he
                  started his Career as a West Region Seller and grew as Country
                  Executive in a short span of 3 years. He has been awarded for
                  5 consecutive years with Hundred Percent Club award and also
                  was presented with the most prestigious “Best of IBM “award.
                  At HP Financial Services he was managing the Western Region
                  business, along with Channel management for IT financing
                  solutions. Earlier to that, he held Branch Management and Key
                  Account Management positions for the financing units of Tata
                  Motors and ORIX
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="about-celpl">
        <div className="container">
          <h1 className="wow fadeIn">Other entities of Connect</h1>
          <h2 className="wow fadeIn">
            Connect Equipment Leasing Private Limited – NBFC
          </h2>
          <a
            className="readmorebtn wow fadeInLeft"
            href="javascript:;"
            data-fancybox
            data-src="#animatedModal"
          >
            Read More
          </a>
          <div
            style={animatedModalStyle}
            id="animatedModal"
            className="animated-modal"
          >
            <div className="abouttext" id="hidden-content">
              <h1 className="">Connect Equipment Leasing Private Limited</h1>
              <p>
                Connect Equipment Leasing Private Limited, established in the
                year 2016 by Founder Directors of Connect Residuary Private
                Limited is registered with RBI as Non-Deposit Accepting
                Non-Banking Financial Company (NBFC).
              </p>
              <p>
                To meet the diverse financial leasing needs of the market, the
                Connect team took a step forward and formed the NBFC which
                enables the customers to utilize their capital for day to day
                business needs rather than blocking the same on capital assets.
                With our vast experience in Asset Life Cycle Management and
                financial expertise, we assist the entities to focus on their
                core business through enhanced liquidity.
              </p>
              <p>
                We cater to the needs of every Company, from Startups, SMEs to
                multinational corporations, by delivering leasing solutions for
                varied equipment with up to 100% financing which covers IT
                solutions including software, machinery, fixtures, and even
                project financing.
              </p>
              <p>
                We cater to Pan India requirements though a well-established
                platform and networking with team presence in Mumbai, NCR and
                Bangalore.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="ourvalue">
        <div className="container">
          <h1 className="wow fadeIn">Our Values</h1>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="valuebox match">
                <img src={Passion} alt="passion" />
                <h2>Passion For People </h2>
                <p>
                  Build a diverse workplace that challenges our employees to
                  grow professionally and embrace teamwork in everything they
                  do.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="valuebox match">
                <img src={Intergrity} alt="intergrity" />
                <h2>Visible Leadership</h2>
                <p>
                  To walk the walk; to lead by example and to do so with a sense
                  of humility and responsibility.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="valuebox match">
                <img src={Driven} alt="driven" />
                <h2>Customer Driven </h2>
                <p>
                  Transparency in everything that we do and a dedicated service
                  attitude to bring delight to our customers.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="valuebox match">
                <img src={Intergrity} alt="integrity" />
                <h2>Absolute Integrity </h2>
                <p>
                  Always do the right thing and honor our commitments; ensure
                  appropriate corporate governance.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="valuebox match">
                <img src={Community} alt="community" />
                <h2>Community Minded </h2>
                <p>
                  Be an outstanding corporate citizen and neighbor at all times
                  in all ways.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="valuebox match">
                <img src={Intergrity} alt="integrity" />
                <h2>Continuous Innovation </h2>
                <p>
                  To foster a culture that empowers employees to improve quality
                  and efficiency.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ourvision">
        <div className="container">
          <h1 className="wow fadeIn">Our Vision</h1>
          <p>
            To be the proactive choice for Equipment Renting and Asset Lifecycle
            Management in India.
          </p>
        </div>
      </section>
    </Body>
  );
}
