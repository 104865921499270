import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function useRouter() {
  const navigate = useNavigate();

  const navigateToScreen = React.useCallback(
    (path: string, replace?: boolean) => {
      navigate(path, { replace });
    },
    [navigate],
  );

  return { navigateToScreen };
}
