import * as React from "react";
import { Header } from "./Header";

interface Props {
  children: React.ReactNode;
}

export const Wrapper = ({ children }: Props) => {
  return (
    <div className="wrapper">
      <Header />
      {children}
    </div>
  );
};
