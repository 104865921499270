import * as React from "react";
import { Body } from "../components/Body";

export default function CSRScreen() {
  return (
    <Body className="page-template page-template-template-parts page-template-csr page-template-template-partscsr-php page page-id-22 has-header-image page-two-column title-tagline-hidden colors-light">
      <div className="innerbanner">
        <h1> CSR </h1>
      </div>

      <section className="csrsection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="col-lg-12 wow fadeInLeft">
                <div className="welcometext">
                  <h1 className="h11">
                    <span> Connecting toward a progressive society </span>
                  </h1>
                  <p>
                    While achieving sustainable growth at Connect, we have
                    realized our need to contribute toward the society by
                    conducting certain corporate social activities as per
                    company policy. Amongst many things, we primarily support
                    education of students from weaker section of society,
                    children that have special needs, and promote causes like
                    social empowerment, training of Youth for Self-Employment,
                    with a focus on Pan-India grassroots development. We
                    pro-actively engage our self in initiatives of empowering
                    traditionally disadvantaged local communities and
                    geographies for building progressive society
                  </p>
                </div>
                <div className="crsinitativebox">
                  <h3>CSR initiatives:</h3>
                  <p>
                    We endeavor constantly to track such Non-profit
                    organizations with whom we can work together and can make a
                    difference to attain our CSR goals
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="pdfbtnbox">
                      <a
                        className="readmorebtn wow fadeInLeft"
                        data-wow-delay=".5s"
                        target="_blank"
                        rel="noreferrer"
                        // TODO:- we need to addpdf into s3 bit bucket and then use url to view pdf
                        href="https://www.connectrpl.com/pdf/CSR_POLICY_NEW.pdf"
                      >
                        CORPORATE SOCIAL RESPONSIBILITY POLICY{" "}
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 wow fadeInRight">
                  <div className="imgbox">
                    <img
                      src="/images/welcomeimg.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                </div> */}
            </div>
          </div>
          {/* <div className="comingsoontext">
              <h1>
                Coming <span>Soon</span>
              </h1>
            </div> */}
        </div>
      </section>
    </Body>
  );
}
