import { useFormikContext } from "formik";
import * as React from "react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onSubmit?: () => void;
}

export const Button = ({ type, children, disabled }: Props) => {
  const formik = useFormikContext();

  const handleSubmit = React.useCallback(() => {
    formik.setSubmitting(true);
    formik.handleSubmit();
    formik.setSubmitting(false);
  }, [formik]);

  const finalDisabled = disabled ?? formik.isSubmitting ?? false;

  return (
    <button type={type} onClick={handleSubmit} disabled={finalDisabled}>
      {children}
    </button>
  );
};
