import * as React from "react";
import { Input } from "../../../components/Input";
import { Spacer } from "../../../components/Spacer";
import { Button } from "../../../components/Button";

export const Form = () => {
  return (
    <div>
      <Input name="name" label="Name" placeholder="Name" />
      <Spacer size={16} />
      <Input name="mobile" label="Mobile" placeholder="Mobile" />
      <Spacer size={16} />
      <Input name="email" label="Email" placeholder="Email" />
      <Spacer size={16} />
      <Input
        name="description"
        label="Description"
        placeholder="Description"
        type="textarea"
      />
      <Spacer size={16} />
      <Button type="submit"> Submit </Button>
    </div>
  );
};
