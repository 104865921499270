import * as React from "react";
import Connect from "../assets/icons/logonew.png";
import Phone from "../assets/icons/blackphone.png";
import { useLocation } from "react-router-dom";
import useRouter from "../hooks/useRouter";

// TODO:- use useNvigate instead href from react-router-dom to prevent page rerender

const pointer = {
  cursor: "pointer",
};

export const Header = () => {
  const { pathname } = useLocation();

  const { navigateToScreen } = useRouter();

  const navigateToHome = React.useCallback(() => {
    navigateToScreen("/");
  }, [navigateToScreen]);

  const navigateToAboutUs = React.useCallback(() => {
    navigateToScreen("/about-us");
  }, [navigateToScreen]);

  const navigateToWhyRent = React.useCallback(() => {
    navigateToScreen("/why-rent");
  }, [navigateToScreen]);

  const navigateToSolutions = React.useCallback(() => {
    navigateToScreen("/solutions");
  }, [navigateToScreen]);

  const navigateToCSR = React.useCallback(() => {
    navigateToScreen("/csr");
  }, [navigateToScreen]);

  const navigateToIndustriesWeCaterTo = React.useCallback(() => {
    navigateToScreen("/industries-we-cater-to");
  }, [navigateToScreen]);

  const navigateToCareer = React.useCallback(() => {
    navigateToScreen("/career");
  }, [navigateToScreen]);

  const navigateToContact = React.useCallback(() => {
    navigateToScreen("/contact");
  }, [navigateToScreen]);

  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar headernav">
              <div className="container">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/">
                    <img src={Connect} alt="connect" />
                  </a>
                </div>

                <div className="upperheader">
                  <img src={Phone} alt="phone" />
                  <a href="tel:+91 22 4879 7600">+91 22 4879 7600 </a>
                </div>
                <div className="collapse navbar-collapse" id="myNavbar">
                  <ul
                    id="menu-main-menu"
                    className="nav navbar-nav navbar-right"
                  >
                    <li
                      id="menu-item-66"
                      className={
                        pathname === "/"
                          ? "menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-7 current_page_item menu-item-66"
                          : "menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-66"
                      }
                      onClick={navigateToHome}
                      style={pointer}
                    >
                      <a aria-current="page">Home</a>
                    </li>
                    <li
                      id="menu-item-123"
                      className={
                        pathname === "/about-us"
                          ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-118 current_page_item menu-item-123"
                          : "menu-item menu-item-type-post_type menu-item-object-page menu-item-123"
                      }
                      onClick={navigateToAboutUs}
                      style={pointer}
                    >
                      <a>About Us</a>
                    </li>
                    <li
                      id="menu-item-64"
                      className={
                        pathname === "/why-rent"
                          ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-17 current_page_item menu-item-64"
                          : "menu-item menu-item-type-post_type menu-item-object-page menu-item-64"
                      }
                      onClick={navigateToWhyRent}
                      style={pointer}
                    >
                      <a>Why Rent</a>
                    </li>
                    <li
                      id="menu-item-63"
                      className={
                        pathname === "/solutions"
                          ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-19 current_page_item menu-item-63"
                          : "menu-item menu-item-type-post_type menu-item-object-page menu-item-63"
                      }
                      onClick={navigateToSolutions}
                      style={pointer}
                    >
                      <a>Solutions</a>
                    </li>
                    <li
                      id="menu-item-62"
                      className={
                        pathname === "/csr"
                          ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-22 current_page_item menu-item-62"
                          : "menu-item menu-item-type-post_type menu-item-object-page menu-item-62"
                      }
                      onClick={navigateToCSR}
                      style={pointer}
                    >
                      <a>CSR</a>
                    </li>
                    <li
                      id="menu-item-61"
                      className={
                        pathname === "/industries-we-cater-to"
                          ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-25 current_page_item menu-item-61"
                          : "menu-item menu-item-type-post_type menu-item-object-page menu-item-61"
                      }
                      onClick={navigateToIndustriesWeCaterTo}
                      style={pointer}
                    >
                      <a>Industries we cater to</a>
                    </li>
                    <li
                      id="menu-item-60"
                      className={
                        pathname === "/career"
                          ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-28 current_page_item menu-item-60"
                          : "menu-item menu-item-type-post_type menu-item-object-page menu-item-60"
                      }
                      onClick={navigateToCareer}
                      style={pointer}
                    >
                      <a>Careers</a>
                    </li>
                    <li
                      id="menu-item-59"
                      className={
                        pathname === "/contact"
                          ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-30 current_page_item menu-item-59"
                          : "menu-item menu-item-type-post_type menu-item-object-page menu-item-59"
                      }
                      onClick={navigateToContact}
                      style={pointer}
                    >
                      <a>Reach Us</a>
                    </li>
                  </ul>{" "}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};
