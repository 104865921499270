import * as React from "react";
import { Body } from "../components/Body";

export default function CareersScreen() {
  return (
    <Body className="page-template page-template-template-parts page-template-career page-template-template-partscareer-php page page-id-28 has-header-image page-two-column title-tagline-hidden colors-light">
      <div className="innerbanner">
        <h1> Career </h1>
      </div>

      <section className="careersection">
        <div className="container">
          <div className="abouttext">
            <p className="wow fadeInUp">
              {" "}
              Connect is about people, values, growing relationships, and
              building a community both in real life and on the net. Our
              policies synchronize the positive energies of our resources by
              cultivating the professional and personal zest of our workforce.
              We believe that work is serious, but we also believe that if we do
              not enjoy what we do, we can never motivate ourselves to excel
              personally or professionally.{" "}
            </p>

            <p className="wow fadeInUp">
              {" "}
              Recruitment at Connect is always an ongoing process and we are
              always open to enrich our team further with quality manpower. If
              this describes you, and you have outstanding relevant skills, you
              could be at Connect. Send us your CV at{" "}
            </p>

            <a href="mailto:connect@connectrpl.com"> connect@connectrpl.com </a>
          </div>
        </div>
      </section>
    </Body>
  );
}
