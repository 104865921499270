import * as React from "react";
import { useFormikContext } from "formik";
import { Spacer } from "./Spacer";

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  inputStyle?: React.CSSProperties;
  type?: "input" | "textarea";
}

const textArea = {
  width: "100%",
};

const errorStyle = {
  fontSize: 8,
  lineHeight: "12px",
  color: "red",
};

export const Input = ({
  name,
  label,
  placeholder,
  inputStyle,
  type = "input",
}: Props) => {
  const formik = useFormikContext();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      formik.setFieldValue?.(name, event.target.value);
    },
    [formik, name],
  );

  const { error, value, touched } = formik.getFieldMeta<string>(name);

  const renderType = React.useCallback(() => {
    if (type === "textarea") {
      return (
        <textarea
          onChange={handleChange}
          style={textArea}
          placeholder={placeholder}
          value={value}
        />
      );
    }
    return (
      <input
        onChange={handleChange}
        style={{ ...inputStyle }}
        placeholder={placeholder}
        value={value}
      />
    );
  }, [handleChange, inputStyle, placeholder, type, value]);

  return (
    <>
      {label !== undefined && (
        <>
          <label>{label}</label>
          <br />
          <Spacer size={8} />
        </>
      )}
      {renderType()}
      {error !== undefined && touched && (
        <>
          <Spacer size={4} />
          <p style={errorStyle}>{error}</p>
        </>
      )}
    </>
  );
};
